import debounce from 'lodash.debounce';
import { Episode, factory, FileType, Playable } from '@vodafoneis/sjonvarpskjarni-js-lib/models';
import { CONTENT_AUTHENTICATED_QUERY, CONTENT_QUERY, FILE_QUERY } from '@vodafoneis/sjonvarpskjarni-js-lib/queries';
import { ImagePreset, merge } from '@vodafoneis/sjonvarpskjarni-js-lib/utils';
import { NotPlayableException } from '@vodafoneis/sjonvarpskjarni-js-lib/exceptions';
import { Request } from '@vodafoneis/sjonvarpskjarni-js-lib/api';
import { BearerAuthInterceptor } from '@vodafoneis/sjonvarpskjarni-js-lib/interceptors';
import { PlaylistItem } from './PlaylistItem';
import { MediaType } from './MediaType';
import { client } from '../utils/ApolloClient';
import { client as restClient } from '../utils/APIClient';
import { PlaylistItemParamsType } from './PlaylistItemParamsType';
import { POSITION_UPDATE_INTERVAL } from '../utils/constants';

const { VOD } = MediaType;

export class MoviePlaylistItem extends PlaylistItem {
	item: Playable;
	subtitle: string | null = null;

	constructor(item: Playable, url: string, accessToken: string) {
		super(VOD, accessToken);

		this.item = item;
		this.url = url;
	}

	getId() {
		return this.item.id;
	}

	static async fromId(params: PlaylistItemParamsType) {
		// Anonymous users don't have an access token
		const headers = params?.accessToken ? { Authorization: `Bearer ${params?.accessToken}` } : {};

		const [{ data }, { data: dataAuthenticated }] = await Promise.all([
			client.query({
				query: CONTENT_QUERY,
				variables: {
					contentId: params?.contentId,
				},
			}),
			client.query({
				query: CONTENT_AUTHENTICATED_QUERY,
				variables: {
					contentId: params?.contentId,
				},
				context: {
					headers,
				},
			}),
		]);

		const movie = factory(merge(data?.content, dataAuthenticated?.content));

		return new MoviePlaylistItem(movie, params?.url, params?.accessToken);
	}

	createEntitlement(accessToken: string, deviceId: string) {
		const watchableLicense = this.item.getWatchableLicense();

		if (!watchableLicense) {
			throw new NotPlayableException();
		}

		return super.createEntitlement(accessToken, {
			deviceId,
			movieId: this.item.id,
			licenseId: watchableLicense.id,
		});
	}

	getImageUrl() {
		return `${process.env.IMAGES_HOST?.replace(/\/*$/, '')}${this.item.getPosterImageUrl({
			presets: [ImagePreset.POSTER_MEDIUM],
		})}`;
	}

	getTitle() {
		if (this.item instanceof Episode) {
			return this.item?.series?.title;
		}

		return this.item?.title;
	}

	getSubtitle() {
		if (this.item instanceof Episode) {
			return this.item?.title;
		}
		return undefined;
	}

	async getUrl() {
		if (!this.url) {
			const { data } = await client.query({
				query: FILE_QUERY,
				variables: { fileId: this.item.getFile(FileType.DASH).id },
			});

			this.url = `${process.env.REQUEST_ROUTER_HOST?.replace(/\/*$/, '')}${data.file.dashUrl}`;
		}

		return this.url;
	}

	_performPositionUpdate = debounce(
		(position) => {
			const request = new Request('position/$$movieId$$', {
				pathVariables: {
					movieId: this.item.id,
				},
				body: {
					position,
				},
			}).addInterceptor(new BearerAuthInterceptor(this.accessToken));

			return restClient.put(request);
		},
		POSITION_UPDATE_INTERVAL * 1000,
		{ maxWait: POSITION_UPDATE_INTERVAL * 1000 }
	);

	setPosition(position: number) {
		if (this.accessToken) {
			this._performPositionUpdate(position);
		}
	}
}
