import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { UUID } from '@vodafoneis/sjonvarpskjarni-js-lib/utils';

const LOCAL_STORAGE_KEY = 'vfi_device_id2';

export class Device {
	static async getDeviceId() {
		let deviceId = localStorage.getItem(LOCAL_STORAGE_KEY);

		if (!deviceId) {
			const fp = await FingerprintJS.load();
			const { visitorId } = await fp.get();
			deviceId = UUID.fromString(visitorId + new Date().toISOString()) as string;
			localStorage.setItem(LOCAL_STORAGE_KEY, deviceId);
		}

		return deviceId;
	}
}
