import { channelFactory, Channel } from '@vodafoneis/sjonvarpskjarni-js-lib/models';
import { CHANNEL_QUERY } from '@vodafoneis/sjonvarpskjarni-js-lib/queries';
import { PlaylistItem } from './PlaylistItem';
import { MediaType } from './MediaType';
import { client } from '../utils/ApolloClient';
import { PlaylistItemParamsType } from './PlaylistItemParamsType';

const { LIVE } = MediaType;

export class ChannelPlaylistItem extends PlaylistItem {
	item: Channel;

	constructor(item: Channel, url: string, accessToken: string) {
		super(LIVE, accessToken);

		this.item = item;
		this.url = url;
	}

	getId() {
		return this.item.id;
	}

	static async fromId(params: PlaylistItemParamsType) {
		const { data } = await client.query({
			query: CHANNEL_QUERY,
			variables: {
				channelId: params?.contentId,
			},
		});

		return new ChannelPlaylistItem(channelFactory(data?.channel), params?.url, params?.accessToken);
	}

	createEntitlement(accessToken: string, deviceId: string) {
		return super.createEntitlement(
			accessToken,
			{
				deviceId,
				channelId: this.item.operatorRef,
			}
		);
	}

	getImageUrl() {
		return `${process.env.IMAGES_HOST?.replace(/\/*$/, '')}${this.item.getIconImageUrl()}`;
	}

	getTitle() {
		return this.item?.title ?? undefined;
	}

	async getUrl() {
		if (!this.url) {
			this.url = `${process.env.REQUEST_ROUTER_HOST?.replace(/\/*$/, '')}${this.item.dashStream}`;
		}

		return this.url;
	}
}
