import debounce from 'lodash.debounce';
import { Request, ERROR_CODES } from '@vodafoneis/sjonvarpskjarni-js-lib/api';
import { BearerAuthInterceptor } from '@vodafoneis/sjonvarpskjarni-js-lib/interceptors';
import { Entitlement } from '@vodafoneis/sjonvarpskjarni-js-lib/models';
import {
	AccountBlockingException,
	AuthorizationException,
	CouldNotCreateEntitlementException,
	EntitlementExpiredException,
	EntitlementLimitReachedException,
	GeoBlockingException,
} from '@vodafoneis/sjonvarpskjarni-js-lib/exceptions';
import { MediaType } from './MediaType';
import { client as restClient, client } from '../utils/APIClient';
import { ENTITLEMENT_UPDATE_INTERVAL } from '../utils/constants';

const { LIVE } = MediaType;
const { ERROR_ACCOUNT_BLOCKING, ERROR_GEO_BLOCKING, ERROR_ENTITLEMENT_LIMIT_REACHED, ERROR_NOT_SUBSCRIBED } = ERROR_CODES;

export class PlaylistItem {
	static currentPlaylistItem?: PlaylistItem;
	url: string | null = null;
	type: MediaType;
	accessToken: string;
	entitlement?: Entitlement | null;

	constructor(type: MediaType, accessToken: string) {
		this.type = type;
		this.accessToken = accessToken;

		PlaylistItem.currentPlaylistItem = this;
	}

	async createEntitlement(accessToken: string, data: any): Promise<Entitlement> {
		const request = new Request('entitlement', {
			body: data,
		});

		if (accessToken) {
			// Anonymous users don't have an access token
			request.addInterceptor(new BearerAuthInterceptor(accessToken));
		}

		const response = await client.post(request);

		if (response.isSuccess() && response.data) {
			this.entitlement = new Entitlement(response.data);
			return this.entitlement;
		}

		if (response.getErrorCode() === ERROR_ACCOUNT_BLOCKING) {
			throw new AccountBlockingException();
		} else if (response.getErrorCode() === ERROR_GEO_BLOCKING) {
			throw new GeoBlockingException();
		} else if (response.getErrorCode() === ERROR_ENTITLEMENT_LIMIT_REACHED) {
			throw new EntitlementLimitReachedException();
		} else if (response.getErrorCode() === ERROR_NOT_SUBSCRIBED) {
			throw new AuthorizationException('Entitlement could not be created, user is not subscribed to content');
		} else {
			throw new CouldNotCreateEntitlementException();
		}
	}

	_performEntitlementUpdate = debounce(
		async () => {
			if (!this.entitlement?.id) return null;

			const request = new Request('entitlement/$$id$$', {
				pathVariables: {
					id: this.entitlement?.id,
				},
			}).addInterceptor(new BearerAuthInterceptor(this.accessToken));

			const response = await restClient.put(request);

			if (response.isSuccess() && response.data) {
				return response.data;
			}

			if (response.statusCode === 404) {
				this.entitlement = null;

				throw new EntitlementExpiredException();
			} else {
				// Ignore all other errors
			}

			return null;
		},
		ENTITLEMENT_UPDATE_INTERVAL * 1000,
		{ maxWait: ENTITLEMENT_UPDATE_INTERVAL * 1000 }
	);

	async updateEntitlement() {
		if (this.entitlement && this.accessToken) {
			await this._performEntitlementUpdate();
		}
	}

	async deleteEntitlement() {
		if (this.entitlement && this.accessToken) {
			try {
				const request = new Request('entitlement/$$id$$', {
					pathVariables: {
						id: this.entitlement?.id,
					},
				}).addInterceptor(new BearerAuthInterceptor(this.accessToken));

				await restClient.del(request);

				this.entitlement = null;
			} catch (exception) {}
		}
	}

	isLive() {
		return this.type === LIVE;
	}

	getImageUrl(): string | null {
		return null;
	}

	getTitle(): string | undefined {
		return undefined;
	}

	getSubtitle(): string | undefined {
		return undefined;
	}

	async preparePlayback() {}

	async getUrl() {
		return this.url;
	}

	cancel() {}

	setPosition(position: number) {}
}
