import { ApolloClient, HttpLink, InMemoryCache, serializeFetchParameter } from '@apollo/client/core';
import { stripIgnoredCharacters } from 'graphql';
import possibleTypes from '../possibleTypes.json';

const customFetch = (uri: string, { body, ...options }: { body: any }) => {
	const { query, variables, operationName } = JSON.parse(body);
	const queryParams = [`query=${encodeURIComponent(stripIgnoredCharacters(query))}`];

	if (variables) {
		queryParams.push(`variables=${encodeURIComponent(serializeFetchParameter(variables, 'variables'))}`);
	}

	if (operationName) {
		queryParams.push(`operationName=${encodeURIComponent(operationName)}`);
	}

	return fetch(`${uri}?${queryParams.join('&')}`, {
		...options,
		method: 'GET',
	});
};

const httpLink = new HttpLink({ uri: `${process.env.API_HOST?.replace(/\/*$/, '')}/graphql`, fetch: customFetch });

export const client = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache({
		possibleTypes,
	}),
});
