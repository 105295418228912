import './index.css';
import { Request } from '@vodafoneis/sjonvarpskjarni-js-lib/api';
import { BasicAuthInterceptor } from '@vodafoneis/sjonvarpskjarni-js-lib/interceptors';
import {
	AccountBlockingException,
	AuthorizationException,
	EntitlementExpiredException,
	EntitlementLimitReachedException,
	GeoBlockingException,
	NotPlayableException,
} from '@vodafoneis/sjonvarpskjarni-js-lib/exceptions';
import toastr from 'toastr';
import { MediaType } from './models/MediaType';
import { Device } from './models/Device';
import { client } from './utils/APIClient';
import { MoviePlaylistItem } from './models/MoviePlaylistItem';
import { ChannelPlaylistItem } from './models/ChannelPlaylistItem';
import { PlaylistItemParamsType } from './models/PlaylistItemParamsType';
import { PlaylistItem } from './models/PlaylistItem';

toastr.options = {
	newestOnTop: false,
	positionClass: 'toast-bottom-full-width',
	preventDuplicates: false,
	timeOut: 10000,
};

const context = cast.framework.CastReceiverContext.getInstance();
const playerManager = context.getPlayerManager();

const { MEDIA_STATUS, LOAD } = cast.framework.messages.MessageType;
const { ERROR, TIME_UPDATE, MEDIA_FINISHED } = cast.framework.events.EventType;

const { LIVE, VOD } = MediaType;

const createPlaylistItem = async (params: PlaylistItemParamsType): Promise<ChannelPlaylistItem | MoviePlaylistItem | null> => {
	switch (params?.mediaType) {
		case LIVE:
			return ChannelPlaylistItem.fromId(params);
		case VOD:
			return MoviePlaylistItem.fromId(params);
		default:
			return null;
	}
};

context.start({
	playbackConfig: Object.assign(new cast.framework.PlaybackConfig(), {
		licenseUrl: 'https://widevine.tv.c.is/license',
	}),
});

playerManager.addEventListener(ERROR, (error) => {
	if (error.error instanceof AccountBlockingException) {
		toastr.error('Aðgangur læstur vegna skuldalokunar');
	} else if (error.error instanceof AuthorizationException) {
		toastr.error('Þú ert ekki áskrifandi að þessu efni');
	} else if (error.error instanceof EntitlementLimitReachedException) {
		toastr.error('Afspilun er nú þegar í gangi á öðru tæki');
	} else if (error.error instanceof GeoBlockingException) {
		toastr.error('Afspilun er eingöngu leyfileg innan EES landa');
	} else if (error.error instanceof NotPlayableException) {
		toastr.error('Afspilun mistókst, þetta efni er ekki aðgengilegt á þessu tæki');
	} else {
		toastr.error('Afspilun mistókst, vinsamlegast reyndu aftur síðar');
	}
});

playerManager.addEventListener(TIME_UPDATE, async ({ currentMediaTime }) => {
	if (currentMediaTime && PlaylistItem.currentPlaylistItem) {
		const playlistItem = PlaylistItem.currentPlaylistItem;

		const position = Math.floor(currentMediaTime);

		playlistItem?.setPosition(position);

		try {
			await playlistItem?.updateEntitlement();
		} catch (exception) {
			if (exception instanceof EntitlementExpiredException) {
				toastr.error('Afspilun hefur verið yfirtekin af öðru tæki');
				playerManager.stop();
			}
		}
	}
});

playerManager.addEventListener(MEDIA_FINISHED, () => {
	PlaylistItem.currentPlaylistItem?.deleteEntitlement();
});

playerManager.setMessageInterceptor(MEDIA_STATUS, (mediaStatus) => {
	if (mediaStatus && mediaStatus.liveSeekableRange) {
		// @ts-ignore
		const { end, start } = mediaStatus.liveSeekableRange;
		const duration = end - start;
		const position = mediaStatus.currentTime;
		const customData = {
			endTime: end,
			startTime: start,
			duration,
			position,
		};

		mediaStatus.customData = { ...mediaStatus.customData, ...customData };
	}
	return mediaStatus;
});

playerManager.setMessageInterceptor(LOAD, async (loadRequestData) => {
	PlaylistItem.currentPlaylistItem?.deleteEntitlement();
	toastr.clear();

	const customData = loadRequestData.customData ?? loadRequestData.media?.customData;

	let url = null;
	let contentId = null;
	let mediaType: MediaType | null = null;
	let deviceAuth = null;
	let accessToken = null;

	if (customData?.sourceDescription) {
		// From THEOplayer
		const { isLive, sourceDescription } = customData;

		const { sources } = sourceDescription;

		const [source] = sources.filter((s: any) => s.type === 'application/dash+xml');

		url = source?.src;
		contentId = source?.contentProtection?.contentId ?? null;
		mediaType = isLive ? LIVE : VOD;
		accessToken = source?.contentProtection?.accessToken ?? source?.contentProtection?.deviceAuth ?? null;
	} else if (customData?.mediaInfo) {
		// From app
		const { mediaInfo, CAInformation } = customData;
		contentId = mediaInfo?.mediaId ?? null;
		mediaType = mediaInfo?.mediaType ?? null;

		accessToken = CAInformation?.accessToken ?? null;
		deviceAuth = CAInformation?.deviceAuth ?? null;
	}

	if (!accessToken && deviceAuth) {
		const request = new Request('login').addInterceptor(new BasicAuthInterceptor(deviceAuth));
		const loginResponse = await client.post(request);
		if (!loginResponse.isSuccess()) {
			// TODO: handle error.
		}

		accessToken = loginResponse?.data?.accessToken ?? null;
	}

	const deviceId = await Device.getDeviceId();

	const playlistItem = await createPlaylistItem({ url, contentId, mediaType, accessToken });
	const entitlement = await playlistItem?.createEntitlement(accessToken, deviceId);

	// Get the correct url for this media
	loadRequestData.media.contentId = (await playlistItem?.getUrl()) ?? contentId;
	loadRequestData.media.contentType = 'application/dash+xml';
	loadRequestData.media.streamType = mediaType === LIVE ? cast.framework.messages.StreamType.LIVE : cast.framework.messages.StreamType.BUFFERED;
	loadRequestData.media.metadata = {
		title: playlistItem?.getTitle(),
		subtitle: playlistItem?.getSubtitle(),
		images: playlistItem?.getImageUrl() ? [new cast.framework.messages.Image(playlistItem?.getImageUrl() ?? '')] : [],
	};

	loadRequestData.media.customData = loadRequestData.media?.customData ?? {};
	loadRequestData.media.customData.CAInformation = {
		token: entitlement?.token,
	};
	loadRequestData.media.customData.mediaInfo = { mediaType, mediaId: contentId };

	return loadRequestData;
});

playerManager.setMediaPlaybackInfoHandler(async (loadRequest, playbackConfig) => {
	playbackConfig.licenseRequestHandler = (requestInfo) => {
		requestInfo.headers.PreAuthorization = loadRequest.media.customData.CAInformation.token;
	};
	return playbackConfig;
});
